<template>
    <main id="main">
      <div class="wrap1160">
        <section id="guide">
          <h1><span>{{$t("GUIDE_AUCTIONAGENCY_H1")}}</span></h1>
          <h2>
            <span class="highlight"
              >{{$t("GUIDE_AUCTIONAGENCY_H2")}}</span
            >
          </h2>
          <p>
            {{ $t("GUIDE_AUCTIONAGENCY_SUPPLEMENT1") }}<br />
            {{ $t("GUIDE_AUCTIONAGENCY_SUPPLEMENT2") }}<br />
            {{ $t("GUIDE_AUCTIONAGENCY_SUPPLEMENT3") }}
          </p>
          <ul class="step">
            <li class="step-1">
              <h3>
                <em>STEP1</em>{{ $t("GUIDE_AUCTIONAGENCY_STEP1_TITLE1") }}<a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_COMMISSION_PAGE_NAME)">{{ $t("GUIDE_AUCTION_COMMISSION") }}</a
                >{{ $t("GUIDE_AUCTIONAGENCY_STEP1_TITLE2") }}
              </h3>
              <div class="conts">
                <div class="outline">
                  <div class="out-txt">
                    <p>
                      {{ $t("GUIDE_AUCTIONAGENCY_STEP1_TEXT1") }}<br />
                    </p>
                    <p class="mark">
                      {{ $t("GUIDE_AUCTIONAGENCY_STEP1_TEXT2") }}
                    </p>
                    <p>
                      <a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_COMMISSION_PAGE_NAME)"
                        >{{$t("GUIDE_AUCTION_COMMISSION_ALL_SORTS")}}</a
                      >
                    </p>
                  </div>
                </div>
                <Button :text="$t('GUIDE_MYPAGE_BTN')" :addClassNameBtn="null" @child-click="$routerGo($define.PAGE_NAMES.MY_PAGE_PAGE_NAME)" class="taC btn-signup"/>
              </div>
            </li>
            <li class="step-2">
              <h3>
                <em>STEP2</em
                ><a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_DELIVERY_PAGE_NAME)">{{ $t("GUIDE_AUCTIONAGENCY_STEP2_TITLE1") }}</a
                >{{ $t("GUIDE_AUCTIONAGENCY_STEP2_TITLE2") }}
              </h3>
              <div class="conts">
                <div class="outline">
                  <div class="out-txt">
                    <p>{{ $t("GUIDE_AUCTIONAGENCY_STEP2_TEXT1") }}</p>
                    <p>
                      <a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_DELIVERY_PAGE_NAME)"
                        >{{$t("GUIDE_AUCTION_TAB1_TEXT6")}}</a
                      >
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li class="step-3">
              <h3><em>STEP3</em>{{ $t("GUIDE_AUCTIONAGENCY_STEP3_TITLE1") }}</h3>
              <div class="conts">
                <div class="outline">
                  <div class="out-txt">
                    <p>
                      {{ $t("GUIDE_AUCTIONAGENCY_STEP3_TEXT1") }}
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li class="step-3">
              <h3><em>STEP4</em>{{ $t("GUIDE_AUCTIONAGENCY_STEP4_TITLE1") }}</h3>
              <div class="conts">
                <div class="outline">
                  <div class="out-txt">
                    <p>
                      {{ $t("GUIDE_AUCTIONAGENCY_STEP4_TEXT1") }}<br />
                      {{ $t("GUIDE_AUCTIONAGENCY_STEP4_TEXT2") }}<br />
                      {{ $t("GUIDE_AUCTIONAGENCY_STEP4_TEXT3") }}<br />
                      {{ $t("GUIDE_AUCTIONAGENCY_STEP4_TEXT4") }}<a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_COMMISSION_PAGE_NAME)"
                        >{{ $t("GUIDE_AUCTION_COMMISSION") }}</a
                      >{{ $t("GUIDE_AUCTIONAGENCY_STEP4_TEXT5") }}<br />
                      {{ $t("GUIDE_AUCTIONAGENCY_STEP4_TEXT6") }}
                    </p>
                    <p class="assist-out-txt">{{$t("GUIDE_AUCTION_STEP4_TEXT4")}}</p>
                  </div>
                </div>
                <ul id="tab_btn">
                  <li @click="select('1')" v-bind:class="{'active': show === '1'}" class="tab-auction">
                    <a href="#tab1"
                      >{{$t("GUIDE_AUCTION_TAB1_TITLE1")}}<br class="only_sp" />{{$t("GUIDE_AUCTION_TAB1_TITLE2")}}</a
                    >
                  </li>
                  <li @click="select('2')" v-bind:class="{'active': show === '2'}" class="tab-tender">
                    <a href="#tab2">{{$t("GUIDE_AUCTION_TAB2_TITLE1")}}</a>
                  </li>
                </ul>
                <ul id="tab_info">
                  <li v-if="show === '1'" class="tab_i-auction">
                    <p class="read">
                      {{ $t("GUIDE_AUCTIONAGENCY_TAB1_TEXT1") }}
                    </p>
                    <h4 class="step">{{ $t("GUIDE_AUCTIONAGENCY_TAB1_H4_1") }}</h4>
                    <div class="dli-arrow">
                      <span class="dli-arrow-down"></span
                      ><span class="txt"
                        >{{ $t("GUIDE_AUCTIONAGENCY_TAB1_TEXT2") }}<a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_COMMISSION_PAGE_NAME)"
                          >{{ $t("GUIDE_AUCTION_COMMISSION") }}</a
                        >{{ $t("GUIDE_AUCTIONAGENCY_TAB1_TEXT3") }}
                      </span>
                    </div>
                    <h4 class="step">{{ $t("GUIDE_AUCTIONAGENCY_TAB1_H4_2") }}</h4>
                    <div class="dli-arrow">
                      <span></span>
                      <span class="txt"
                        >{{ $t("GUIDE_AUCTIONAGENCY_TAB1_TEXT7") }}</span
                      >
                    </div>
                  </li>

                  <li v-else-if="show === '2'" class="tab_i-tender">
                    <h4 class="step">{{ $t("GUIDE_AUCTIONAGENCY_TAB2_H4_1") }}</h4>
                    <div class="dli-arrow">
                      <span class="dli-arrow-down"></span
                      ><span class="txt"
                        >{{ $t("GUIDE_AUCTIONAGENCY_TAB2_TEXT1") }}<br />
                        {{ $t("GUIDE_AUCTIONAGENCY_TAB2_TEXT2") }}
                      </span>
                    </div>
                    <h4 class="step">{{ $t("GUIDE_AUCTIONAGENCY_TAB2_H4_2") }}</h4>
                    <div class="dli-arrow">
                      <span class="dli-arrow-down"></span
                      ><span class="txt"
                        >{{ $t("GUIDE_AUCTIONAGENCY_TAB2_TEXT3") }}<a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_COMMISSION_PAGE_NAME)"
                          >{{ $t("GUIDE_AUCTION_COMMISSION") }}</a
                        >{{ $t("GUIDE_AUCTIONAGENCY_TAB2_TEXT4") }}
                      </span>
                    </div>
                    <h4 class="step">{{ $t("GUIDE_AUCTIONAGENCY_TAB2_H4_3") }}</h4>
                    <div class="dli-arrow">
                      <span class="dli-arrow-down"></span
                      ><span class="txt"
                        >{{ $t("GUIDE_AUCTIONAGENCY_TAB2_TEXT5") }}
                      </span>
                    </div>
                    <h4 class="step">{{ $t("GUIDE_AUCTIONAGENCY_TAB2_H4_4") }}</h4>
                    <div class="dli-arrow">
                      <span></span
                      ><span class="txt"
                        >{{ $t("GUIDE_AUCTIONAGENCY_TAB2_TEXT6") }}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </section>
      </div>
    </main>
</template>

<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
<style scoped lang="sass" src="@/assets/scss/guide.sass"></style>

<script>
import Button from '../../components/Guide/Parts/Button';

export default {
  data() {
    return {
      show : '1',
    }
  },
  components : {
    Button,
  },
  methods : {
    select(num) {
      this.show = num
    }
  }
}
</script>
