import { render, staticRenderFns } from "./Auctionagency.vue?vue&type=template&id=3bbf7c65&scoped=true&"
import script from "./Auctionagency.vue?vue&type=script&lang=js&"
export * from "./Auctionagency.vue?vue&type=script&lang=js&"
import style0 from "@/assets/scss/style.scss?vue&type=style&index=0&id=3bbf7c65&prod&scoped=true&lang=scss&"
import style1 from "@/assets/scss/guide.sass?vue&type=style&index=1&id=3bbf7c65&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bbf7c65",
  null
  
)

export default component.exports